import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { ServerService } from './server.service';
import { SquareComponent } from './square/square.component'
import { Routes, RouterModule } from '@angular/router'
import { CreditsComponent } from './credits/credits.component';
import { PolicyComponent } from './policy/policy.component';
import { HomeComponent } from './home/home.component'
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from "@angular/fire/auth";

const appRoutes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'credits', component: CreditsComponent },
  { path: 'policy', component: PolicyComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    SquareComponent,
    CreditsComponent,
    PolicyComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    RouterModule.forRoot(appRoutes),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    HttpClientModule,
  ],
  providers: [ServerService],
  bootstrap: [AppComponent]
})
export class AppModule { }
