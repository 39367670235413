import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ServerService {
  
  constructor(private http: HttpClient) { }

  getData() : Observable<Object> {
    return this.http.get('https://dedo1911.xyz/glitch4ndroid/');
  }

  // getData() : Observable<Object> {
  //   return this.http.get(
  //     'https://www.instagram.com/api/v1/tags/logged_out_web_info/?tag_name=glitch4ndroid',{
  //       headers: {
  //         'authority': 'www.instagram.com',
  //         'accept': '*/*',
  //         'accept-language': 'it-IT,it;q=0.9',
  //         'x-ig-app-id': '936619743392459'
  //       }
  //    });
  // }
  getVideoData(postId: string) {
    return this.http.get(`https://www.instagram.com/p/${postId}/?__a=1`);
  }
}