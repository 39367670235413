import { Component, OnInit } from '@angular/core'
import * as M from 'materialize-css'

@Component({
  selector: 'app-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.css']
})
export class CreditsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
      var elems = document.querySelectorAll('.parallax');
      M.Parallax.init(elems);
  }

}
