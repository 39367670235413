import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { ServerService } from '../server.service'

@Component({
  selector: 'app-square',
  templateUrl: './square.component.html',
  styleUrls: ['./square.component.css']
})
export class SquareComponent implements OnInit {

  @Input() node: any;

  videoUrl = ''

  constructor(private serverService: ServerService) { }

  ngOnInit() {

    if(this.node.video) {

      this.serverService.getVideoData(this.node.shortcode)
        .subscribe((response: any) => 
          this.videoUrl = response.graphql.shortcode_media.video_url,

        (error: HttpErrorResponse) => 
          console.log(`failed to load ${this.node.shortcode} video`))
    }

  }

}
