
<span id="legend" [innerHTML]=legend></span>
<div id="photos" class="wrap">
  
  <div class="main">

      <div class="box">
        <div class="boxInner">
          <a href="https://play.google.com/store/apps/details?id=com.lucagrillo.ImageGlitcher" ng-href="">
            <img src="assets/img/Play_Store.jpg">
          </a>
        </div>
      </div>

      <div *ngFor="let node of data" class="box">
      
        <app-square [node]=node></app-square>

      </div>

  </div>

</div>
