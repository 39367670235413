<div class="container black">
  <div class="row">
    <div class="col m2 l3"></div>
    <div class="col s12 m8 l6">

        <div class="icon-block">
          <h2 class="center white-text"><i class="material-icons">verified_user</i></h2>
          <h5 class="center white-text">Privacy Policy</h5>              
        </div>
        <br/>

        <p class="white-text">
          We respect your privacy. We won’t use your E-mail id and name or contact you in reference to any issue which is currently open or was open in past between you and GLITCH!
        </p>
        
        <p class="white-text">
              We won’t share your personal data e.g. E-mail id, Contact Number with any third party which is not related with <a href="www.glitch4ndroid.com">www.glitch4ndroid.com</a>
        </p>

        <p class="white-text">
              Google Analytics, a third-party app for logging errors, may add a cookie based on your preferences and your visit to our site and other sites on the internet. You can choose to opt out of Google’s use of cookies by visiting the Google ad and content network privacy policy
        </p>

    </div>
    <div class="col m2 l3"></div>
  </div>
</div>