
	<div class="boxInner" >
		<a href="https://www.instagram.com/p/{{ node.shortcode }}">
			
			<img *ngIf="!node.video" src="{{ node.src }}" crossorigin="anonymous"/>
			<video *ngIf="node.video" autoplay src="{{ videoUrl }}" poster="{{ node.src }}"></video>

		</a>\
		<div class="titleBox" *ngIf="node.caption != ''">
			<a href="https://www.instagram.com/p/{{ node.shortcode }}">
				{{ node.caption }} (&#9829; {{ node.likes }})
			</a>
		</div>
	</div>
