// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCKcMmp1jukEnPdbtlMnWSuQH_ozUZxVQY",
    authDomain: "glitch4ndroid-8282.firebaseapp.com",
    databaseURL: "https://glitch4ndroid-8282.firebaseio.com",
    projectId: "glitch4ndroid-8282",
    storageBucket: "glitch4ndroid-8282.appspot.com",
    messagingSenderId: "458701809249",
    appId: "1:458701809249:web:ffd446bf1a902eb782ac20",
    measurementId: "G-7TFL78XZCD"
  }
};