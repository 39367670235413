<div class="row">
  <div class="col m2 l3 invisible"></div>
  <div class="col s12 m8 l6">

    <div id="index-banner" class="parallax-container" >
      <div class="section no-pad-bot">
        <div class="container">
          <br><br>
          <h1 class="header center">special thanks</h1>
          <div class="row center">
            <h5 class="header col s12 light">to everyone who helped and contributed</h5>
          </div>

          <br><br>

        </div>
      </div>
      <div class="parallax">
        <img src="assets/img/background1.jpg">
      </div>
    </div>

    <div class="section shadow white">
      <!--   Icon Section   -->
      <div class="row">
        <div class="col s12">
          <div class="icon-block">
            <h2 class="center black-text"><i class="material-icons">translate</i></h2>
            <h5 class="center">Translations</h5>
            <ul>
              <li><span>Octavio Castiñeira (ES)</span></li>
              <li><a href="http://okytomo.tumblr.com/">okytomo.tumblr.com</a></li>
            </ul>
            <ul>
              <li><span>Sofia Chicherina (RU)</span></li>
              <li><a href="http://sofiachicherina.wordpress.com/">sofiachicherina.wordpress.com</a></li>
            </ul>
            <ul>
              <li><span>Jessica Mertens (DE)</span></li>
              <li><a href="https://www.facebook.com/jessica.mertens.771/">facebook.com/jessica.mertens.771</a></li>
            </ul>
          </div>
        </div>

      </div>
    </div>

    <div class="parallax-container valign-wrapper" >
      <div class="section no-pad-bot">
        <div class="container">
          <div class="row center">
            <h5 class="header col s12 light"></h5>
          </div>
        </div>
      </div>
      <div class="parallax" ><img src="assets/img/background2.jpg"></div>
    </div>

    <div class="section shadow white">

        <!--   Icon Section   -->
        <div class="row">
          <div class="col s12">
            <div class="icon-block">
              <h2 class="center black-text"><i class="material-icons">invert_colors</i></h2>
              <h5 class="center">Graphics</h5>
              <ul>
                <li><span>Giacomo Carmagnola</span></li>
                <li><a href="http://giacomocarmagnola.tumblr.com">giacomocarmagnola.tumblr.com</a></li>
              </ul>
              <ul>
                <li><span>Lola Glitchesbabe</span></li>
                <li><a href="https://www.instagram.com/glitchesbabe">instagram.com/glitchesbabe</a></li>
              </ul>
              <ul>
                <li><span>Cristiana Trimboli for sickymag.com</span></li>
                <li><a href="http://sickymag.com">sickymag.com</a></li>
              </ul>
              <ul>
                <li><span>Gran Turismo オーバードライブ89</span></li>
                <li><a href="https://granturismo89.tumblr.com/">granturismo89.tumblr.com</a></li>
              </ul>
            </div>
          </div>

        </div>
    </div>

    <div class="parallax-container valign-wrapper" >
      <div class="section no-pad-bot">
        <div class="container">
          <div class="row center">
            <h5 class="header col s12 light"></h5>
          </div>
        </div>
      </div>
      <div class="parallax" ><img src="assets/img/background3.jpg"></div>
    </div>

    <div class="section shadow white">

        <!--   Icon Section   -->
        <div class="row">
          <div class="col s12">
            <div class="icon-block">
              <h2 class="center black-text"><i class="material-icons">movie</i></h2>
              <h5 class="center">Video teaser</h5>

              <ul>
                <li><span>Kuro Silvia</span></li>
                <li><a href="https://kurosilvia.wordpress.com">kurosilvia.wordpress.com</a></li>
              </ul>
              <ul>
                <li><span>Kreativ In Den Boden</span></li>
                <li><a href="https://kidb.bandcamp.com">kidb.bandcamp.com</a></li>
              </ul>
              <ul>
                <li><span>Chiara Condoleo</span></li>
                <li><a href="https://www.instagram.com/chiara_wantsacracker">instagram.com/chiara_wantsacracker</a></li>
              </ul>

            </div>
          </div>

        </div>
    </div>

    <div class="parallax-container valign-wrapper" >
      <div class="section no-pad-bot">
        <div class="container">
          <div class="row center">
            <h5 class="header col s12 light"></h5>
          </div>
        </div>
      </div>
      <div class="parallax" >
        <video autoplay loop muted >
          <source src="assets/video/teaser.mp4" type="video/mp4">
        </video>
      </div>
    </div>

    <footer class="section shadow white">
      <div class="container">
        <div class="row">
          <div class="col s12">
            <div class="icon-block">
              <h2 class="center black-text"><i class="material-icons">code</i></h2>
              <h5 class="black-text">App Developer</h5>
              <ul>
                <li><span>Grillo Luca</span></li>
                <li><a href="http://www.glitch4ndroid.com">glitch4ndroid.com</a></li>
              </ul>
              <ul>
                <li><a href="http://t.me/lucagrillo">Telegram</a></li>
                <li><a href="https://www.instagram.com/glitch4ndroid/">Instagram</a></li>
                <li><a href="mailto:grillo.luca@gmail.com" target="_top">Send Mail</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>

  </div>
  <div class="col m2 l3 invisible"></div>
</div>
