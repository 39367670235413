import { Component, OnInit } from '@angular/core';
import { ServerService } from '../server.service'
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from "@angular/fire/auth";
import { HostListener } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import {forkJoin} from 'rxjs';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  
  legend = ""
  firestore: AngularFirestore
  fireauth: AngularFireAuth
  data: any[] = [{node: ""}]

  ngOnInit() {
    this.randomize()
  }

  constructor(private serverService: ServerService, firestore: AngularFirestore, fireauth: AngularFireAuth) {
    this.firestore = firestore
    this.fireauth = fireauth
    this.serverService.getData()
        .subscribe((response: any) => {
            this.data = this.convert(response.data.hashtag.edge_hashtag_to_media.edges)
            //this.saveHashtag(this.data)
        },
        (error: HttpErrorResponse) => {
          console.error(error);
          this.fireauth.signInAnonymously().then(() =>
            this.firestore
              .collection('hashtag').doc('hashtag')
              .get()
              .subscribe(event => {
                  this.data = (event.data() as any).elms
                }              
            ))
        })
  }

  convert = (elms: any[]) => elms.map(elm => {
        
      return {
        shortcode: elm.node.shortcode,
        src: elm.node.thumbnail_src,
        caption: elm.node.edge_media_to_caption ? elm.node.edge_media_to_caption.edges[0].node.text : "",
        likes: elm.node.edge_media_to_caption ? elm.node.edge_liked_by.count : 0,
        url: elm.node.display_url,
        video: elm.node.is_video
      }
  })

  saveHashtag = (elms: any[]) => {
    new Promise<any>((resolve, reject) => { 
      this.fireauth.signInAnonymously().then(() =>
        this.firestore.collection('hashtag')
          .doc('hashtag')
          .set({elms}, {merge: true})
          .then(
            res => {},
            err => reject(err)
        ))
    })
  }

  @HostListener('window:scroll', ['$event']) onScrollEvent(){
    this.randomize()
  } 
  
  randomize = () => {
      this.legend = ['g','l','i','t','c','h','4','n','d','r','o','i','d'].map(letter => {
      
        let spaces = ''
        for(let s=0; s < Math.random() * 5; s++) 
          spaces += '&nbsp;'
  
        return `${letter}${spaces}`

      }).join('');
    }
}
